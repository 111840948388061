import React, { Fragment, createRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHourglass, faTimes } from '@fortawesome/free-solid-svg-icons'

import styles from './Dropzone.css'

export default class Dropzone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dragging: false
    }
    this.dropRef = createRef()
    this.inputRef = createRef()
  }

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true })
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({ dragging: false })
    }
  }

  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ dragging: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      Object.keys(e.dataTransfer.files).map((idx) => {
        const file = e.dataTransfer.files[idx]
        this.getBase64(
          file.name.split('.')[0],
          file.name.split('.').pop(),
          file.type,
          file
        )
        return null
      })

      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }

  getBase64 = (name, extension, type, file) => {
    let data = {
      name,
      extension,
      type,
      base64: null,
      status: 0
    }

    var reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => {
      if (
        ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(type)
      ) {
        if (this.props.maxSize) {
          let canvas = document.createElement('canvas')
          let image = new Image()
          image.src = reader.result
          image.onload = () => {
            let finalWidth = 0
            let finalHeight = 0
            const initialWidth = image.width
            const initialHeight = image.height
            if (
              this.props.maxSize < initialWidth ||
              this.props.maxSize < initialHeight
            ) {
              if (initialWidth > initialHeight) {
                if (initialWidth > this.props.maxSize) {
                  finalWidth = this.props.maxSize
                  finalHeight = (finalWidth * initialHeight) / initialWidth
                } else {
                  finalWidth = initialWidth
                  finalHeight = initialHeight
                }
              } else {
                if (initialHeight > this.props.maxSize) {
                  finalHeight = this.props.maxSize
                  finalWidth = (finalHeight * initialWidth) / initialHeight
                } else {
                  finalWidth = initialWidth
                  finalHeight = initialHeight
                }
              }

              const context = canvas.getContext('2d')
              canvas.width = finalWidth
              canvas.height = finalHeight
              context.drawImage(image, 0, 0, finalWidth, finalHeight)
              const fileRendered = canvas.toDataURL('image/jpeg', 0.92)
              data.base64 = fileRendered
              this.onFile(data)
            } else {
              data.base64 = reader.result
              this.onFile(data)
            }
          }
          image.onerror = () => {
            data.status = 2
            this.onFile(data)
          }
        } else {
          data.base64 = reader.result
          this.onFile(data)
        }
      } else {
        data.status = 2
        this.onFile(data)
      }
    }
    reader.onerror = () => {
      data.status = 2
      this.onFile(data)
    }
  }

  onFile = (data) => {
    if (this.props.onFile) {
      this.props.onFile(data)
    }
  }

  componentDidMount() {
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }

  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }

  getFile = (data) => {
    switch (data.status) {
      case 0:
        return (
          <div className={styles.orange}>
            <span>
              {data.name}.{data.extension}
            </span>
            <span>
              <FontAwesomeIcon icon={faHourglass} />
            </span>
          </div>
        )
      case 1:
        return (
          <div className={styles.green}>
            <span>
              {data.name}.{data.extension}
            </span>
            <span>
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </div>
        )
      case 2:
        return (
          <div className={styles.red}>
            <span>
              {data.name}.{data.extension}
            </span>
            <span>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
        )
      default:
        return null
    }
  }

  render() {
    return (
      <>
        <div className={styles.dropzone}>
          <div ref={this.dropRef} onClick={() => this.inputRef.current.click()}>
            <span>Déposer vos fichiers ici</span>
          </div>
          <div>
            {this.props.files
              ? this.props.files.map((element, idx) => (
                  <Fragment key={idx}>{this.getFile(element)} </Fragment>
                ))
              : null}
          </div>
        </div>
        <input
          type='file'
          multiple
          accept='image/png, image/jpeg, image/jpg'
          ref={this.inputRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            Object.keys(e.target.files).map((idx) => {
              const file = e.target.files[idx]
              this.getBase64(
                file.name.split('.')[0],
                file.name.split('.').pop(),
                file.type,
                file
              )
              return null
            })
          }}
        />
      </>
    )
  }
}
