import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Input.css";

export default class Input extends React.Component {
  render() {
    return (
      <div className={styles.input}>
        {this.props.title ? <span>{this.props.title}</span> : null}
        <div>
          {this.props.icon ? (
            <div>
              <span>
                <FontAwesomeIcon icon={this.props.icon} />
              </span>
            </div>
          ) : (
            null
          )}
          <input
            ref={this.props.refInput}
            type={this.props.type}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            value={this.props.value}
            name={this.props.name}
            required={this.props.required}
          />
        </div>
      </div>
    );
  }
}
