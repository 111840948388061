import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Button.css";

export default class Button extends React.Component {
  render() {
    return (
      <div
        className={styles.button}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <span>{this.props.text}</span>
        {this.props.icon ? (
          <span>
            <FontAwesomeIcon icon={this.props.icon} />
          </span>
        ) : (
          null
        )}
      </div>
    );
  }
}
