import React from "react";

import styles from "./Title.css";

export default class Title extends React.Component {
  render() {
    return (
      <div className={styles.title}>
        <div>
          <h2>{this.props.title}</h2>
        </div>
        <div>{this.props.children}</div>
      </div>
    );
  }
}
