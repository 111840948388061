import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import styles from "./NavItem.css";

export default class NavItem extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          styles.nav_item,
          this.props.active ? styles.active : null,
          this.props.disabled ? styles.disabled : null
        )}
        onClick={() => (!this.props.disabled ? this.props.onClick() : null)}
      >
        <span>
          <FontAwesomeIcon icon={this.props.icon} />
        </span>
        <span>{this.props.text}</span>
        <div />
      </div>
    );
  }
}
