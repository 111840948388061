import React from 'react'
import classNames from 'classnames'

import styles from './Radio.css'

export default class Radio extends React.Component {
  render() {
    return (
      <div className={classNames(styles.radio_container, this.props.className)}>
        <label>
          {this.props.text}
          <input
            type='radio'
            name={this.props.name}
            value={this.props.value}
            checked={this.props.checked}
            onChange={this.props.onChange}
          />
          <span className={styles.radio_mark} />
        </label>
      </div>
    )
  }
}
