import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import styles from './Icon.css'

export default class Icon extends React.Component {
  render() {
    return (
      <div className={styles.icon_parent}>
        <div
          onClick={this.props.onClick}
          className={classNames(
            styles.icon,
            this.props.active ? styles.active : null
          )}
        >
          <span>
            <FontAwesomeIcon icon={this.props.icon} />
          </span>
          <div />
        </div>
        {this.props.active ? (
          <div className={styles.icon_menu}>
            <h3>{this.props.title}</h3>
            <div>
              {this.props.content ? (
                this.props.content
              ) : (
                <span className={styles.icon_menu_empty}>
                  {this.props.empty}
                </span>
              )}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
