import React from "react";

import styles from "./Loader.css";

export default class Loader extends React.Component {
  render() {
    if (!this.props.show) return null;

    return (
      <div className={styles.loader}>
        <div>
          <div>
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    );
  }
}
