import React, { Fragment } from 'react'
import classNames from 'classnames'

import styles from './Table.css'

export default class Table extends React.Component {
  render() {
    return (
      <Fragment>
        <h3 className={styles.table_title}>{this.props.title}</h3>
        <table className={classNames(styles.table, this.props.className)}>
          <thead>{this.props.thead}</thead>
          <tbody>{this.props.tbody}</tbody>
        </table>
      </Fragment>
    )
  }
}
