import React from "react";

import styles from "./Header.css";

import Logo from "./Logo";
import Nav from "./Nav";

export default class Header extends React.Component {
  render() {
    return (
      <div className={styles.header}>
        <div>
          <Logo logo={this.props.logo} onClick={this.props.onLogoClick} />
          <Nav>{this.props.left}</Nav>
        </div>
        <div>{this.props.right}</div>
      </div>
    );
  }
}
