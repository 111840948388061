import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'

import styles from './Profil.css'

export default class Profil extends React.Component {
  render() {
    return (
      <div className={styles.profil_parent}>
        <div onClick={this.props.onClick} className={styles.profil}>
          <div>
            <span>{this.props.text}</span>
          </div>
          <div />
        </div>
        {this.props.active ? (
          <div className={styles.profil_menu}>
            <div>
              <div>
                <div>
                  <span>{this.props.text}</span>
                </div>
              </div>
              <div>
                <span>{this.props.email}</span>
                <span>{this.props.grade}</span>
              </div>
            </div>
            <div>
              {this.props.onProfilClick ? (
                <div onClick={this.props.onProfilClick}>
                  <span>
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <span>Mon profil</span>
                </div>
              ) : null}
              {this.props.onSignoutClick ? (
                <div onClick={this.props.onSignoutClick}>
                  <span>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </span>
                  <span>Se déconnecter</span>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
