import React from 'react'

import styles from './Loader.css'

import Loader from './Loader'

export default class FullLoader extends React.Component {
  componentDidUpdate() {
    if (this.props.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }

  render() {
    if (!this.props.show) return null

    return (
      <div className={styles.full_loader}>
        <Loader show={true} />
      </div>
    )
  }
}
