import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './Select.css'

export default class Select extends React.Component {
  render() {
    return (
      <div className={styles.select}>
        {this.props.title ? <span>{this.props.title}</span> : null}
        <div>
          {this.props.icon ? (
            <div>
              <span>
                <FontAwesomeIcon icon={this.props.icon} />
              </span>
            </div>
          ) : null}
          <select
            ref={this.props.refSelect}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            value={this.props.value}
            name={this.props.name}
            required={this.props.required}
            defaultValue='DEFAULT'
          >
            <option disabled value='DEFAULT'>
              {this.props.defaultValue}
            </option>
            {this.props.options.map((element, idx) => (
              <option
                key={idx}
                value={element.value}
                disabled={element.disabled ? element.disabled : false}
              >
                {element.text}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
}
