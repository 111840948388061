import React from "react";

import styles from "./Logo.css";

export default class Logo extends React.Component {
  render() {
    return (
      <div className={styles.logo} onClick={this.props.onClick}>
        <img src={this.props.logo} alt="logo" />
      </div>
    );
  }
}
