import React from 'react'
import PaginationCMP from 'react-js-pagination'
import classNames from 'classnames'
import styles from './Pagination.css'

class Pagination extends React.Component {
  render() {
    return (
      <div
        style={this.props.style}
        className={classNames(
          styles.pagination_container,
          this.props.className
        )}
      >
        <PaginationCMP
          innerClass={styles.pagination}
          activeClass={styles.active}
          disabledClass={styles.disabled}
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.itemsCountPerPage}
          totalItemsCount={this.props.totalItemsCount}
          pageRangeDisplayed={this.props.pageRangeDisplayed}
          onChange={this.props.onChange}
          firstPageText={this.props.firstPageText}
          lastPageText={this.props.lastPageText}
          prevPageText={this.props.prevPageText}
          nextPageText={this.props.nextPageText}
        />
      </div>
    )
  }
}

export default Pagination
