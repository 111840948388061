import React from 'react'
import classNames from 'classnames'

import styles from './Card.css'

export default class Card extends React.Component {
  render() {
    return (
      <div className={classNames(styles.card, this.props.className)}>
        <div
          className={classNames(styles.card_header, this.props.classNameHeader)}
        >
          <h2>{this.props.title}</h2>
        </div>
        <div className={classNames(styles.card_body, this.props.classNameBody)}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
