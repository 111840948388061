import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './ButtonIcon.css'

export default class ButtonIcon extends React.Component {
  render() {
    return (
      <div
        className={styles.button_icon}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <span>
          <FontAwesomeIcon icon={this.props.icon} />
        </span>
      </div>
    )
  }
}
