import React from "react";
import classNames from "classnames";

import styles from "./Checkbox.css";

export default class Checkbox extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          styles.checkbox_container,
          this.props.className
        )}
      >
        <label className={classNames(this.props.className)}>
          {this.props.text}
          <input
            type='checkbox'
            name={this.props.name}
            checked={this.props.checked}
            onChange={this.props.onChange}
          />
          <span className={styles.checkbox_mark} />
        </label>
      </div>
    );
  }
}
