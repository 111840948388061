import React from "react";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Modal.css";

export default class Modal extends React.Component {
  componentDidUpdate() {
    if (this.props.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className={styles.modal}>
        <div>
          <div>
            <div>
              <h2>{this.props.title}</h2>
            </div>
            <div>
              {this.props.onClose ? (
                <div onClick={this.props.onClose}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              ) : (
                null
              )}
              {this.props.onValid ? (
                <div onClick={this.props.onValid}>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              ) : (
                null
              )}
            </div>
          </div>
          <div>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
